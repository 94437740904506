import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { StatsCard } from "@/game-val/LiveMatch.style.jsx";
import { getWinRateColor } from "@/game-val/utils.mjs";
import { calcRate } from "@/util/helpers.mjs";

const MapNameContainer = styled("div")`
  width: calc(var(--sp-10) * 8.2);
  height: auto;
  border-radius: var(--br);
  background-image:
    linear-gradient(to bottom, transparent, var(--shade10-75) 62.5%),
    url(${(props) => props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-shrink: 0;

  text-align: center;

  padding-top: calc(var(--sp-10) * 3.4);
  padding-bottom: calc(var(--sp-4));

  .map-stats {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--shade2);

    .round-result {
      font-size: var(--sp-3);
      font-weight: 700;
    }

    .map-win-loss {
      text-transform: capitalize;
    }

    span {
      margin-left: calc(var(--sp-1) * 0.5);
      margin-right: calc(var(--sp-1) * 0.5);
    }
  }
`;

const MapStatsCard = styled(StatsCard)`
  .win-rate {
    font-size: var(--sp-5);
    margin-right: var(--sp-1);
  }

  .win-rates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--sp-1);
    align-items: baseline;
  }
`;

const MapStatsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled("div")`
  width: 100%;
  height: var(--sp-0_5);
  background: var(--shade5);
`;

const MapStats = ({ mapMeta, mapData = {}, hideStats = false }) => {
  const { t } = useTranslation();
  const {
    atkRoundsWon = 0,
    atkRoundsLost = 0,
    defRoundsWon = 0,
    defRoundsLost = 0,
    matchesPlayed = 0,
    matchesWon = 0,
    matchesLost = 0,
  } = mapData;

  const ties = matchesPlayed - matchesWon - matchesLost;
  const attackingPlayed = atkRoundsWon + atkRoundsLost;
  const defendingPlayed = defRoundsWon + defRoundsLost;

  const hasMatches = matchesPlayed > 0;
  const winPercent = hasMatches
    ? calcRate(matchesWon, matchesPlayed - (ties || 0), 2)
    : "-";
  const defenseWinPercent = hasMatches
    ? calcRate(defRoundsWon, defendingPlayed, 2)
    : "-";
  const attackWinPercent = hasMatches
    ? calcRate(attackingPlayed, attackingPlayed, 2)
    : "-";

  return (
    <div>
      <MapNameContainer src={mapMeta?.images?.hero} alt={mapMeta?.name}>
        <p className="map-name type-subtitle1">
          {t("val:mapName", "{{mapName}}", { mapName: mapMeta?.name })}
        </p>
      </MapNameContainer>
      <Divider />
      {!hideStats && (
        <MapStatsCard>
          <p className="type-body2 title">
            {t("val:mapWinRate", "Map Win Rate")}
          </p>
          <MapStatsContainer>
            <div>
              <p className="map-stats type-caption--bold">
                <span
                  className="win-rate"
                  style={{ color: getWinRateColor(winPercent) }}
                >
                  {hasMatches
                    ? t("common:percent", "{{percent, percent}}", {
                        percent: winPercent,
                      })
                    : t("common:notAvailable", "N/A")}
                </span>
                <span className="type-caption--semi shade2">
                  {t("common:stats.winsDashLosses", "{{wins}}W - {{losses}}L", {
                    wins: matchesWon,
                    losses: matchesLost,
                  })}
                </span>
              </p>
              <p className="type-caption--semi shade2">
                {t(
                  "common:stats.recentnGames",
                  "Recent {{numberOfGames}} Games",
                  {
                    numberOfGames: matchesPlayed,
                  },
                )}
              </p>
            </div>
            <div>
              <p className="win-rates">
                <span className="map-win-loss type-caption--semi shade2">
                  {t("val:attack", "Attack")}
                </span>
                <span style={{ color: getWinRateColor(attackWinPercent) }}>
                  {hasMatches
                    ? t("common:percent", "{{percent, percent}}", {
                        percent: attackWinPercent,
                      })
                    : t("common:notAvailable", "N/A")}
                </span>
              </p>
              <p className="win-rates">
                <span className="map-win-loss type-caption--semi shade2">
                  {t("common:defense", "Defense")}
                </span>
                <span style={{ color: getWinRateColor(defenseWinPercent) }}>
                  {hasMatches
                    ? t("common:percent", "{{percent, percent}}", {
                        percent: defenseWinPercent,
                      })
                    : t("common:notAvailable", "N/A")}
                </span>
              </p>
            </div>
          </MapStatsContainer>
        </MapStatsCard>
      )}
    </div>
  );
};

export default MapStats;
